import { Slider } from '@mui/material'
import { Box } from '@mui/system'
import React, { FC, SyntheticEvent } from 'react'
import { sliderStyle } from '../../..//Rating/components/SliderRating/styles'
import { MultiSliderRatingProps } from '../../../../../util/types'
import { useThemeContext } from '../../../../../globalContext/global-context'

const MultiSliderComp: FC<MultiSliderRatingProps> = ({
  interval,
  UpdateMarkValue,
  sliderValue,
  setSliderValue,
  parentIndex,
}): React.ReactElement => {
  const { globalObject } = useThemeContext()
  const globalTheme = globalObject?.themeData?.theme

  const handleCommitChange = (
    event: SyntheticEvent | Event,
    value: number | number[],
  ) => {
    if (typeof value === 'number') {
      setSliderValue((prevValues) => ({
        ...prevValues,
        [parentIndex]: {
          sliderRatingValue: value,
        },
      }))
    }
  }

  const isSlider = sliderValue[parentIndex]?.sliderRatingValue === undefined

  return (
    <Box sx={{ width: '100%' }}>
      <Slider
        data-test-id="slider"
        onChangeCommitted={handleCommitChange}
        aria-label="Custom marks"
        defaultValue={undefined}
        step={interval}
        value={sliderValue[parentIndex]?.sliderRatingValue}
        marks={UpdateMarkValue}
        sx={sliderStyle(globalTheme, isSlider)}
      />
    </Box>
  )
}

export default MultiSliderComp
