import { ChatController, MessageContent } from 'chat-ui-react'

export const langUageBaseUpadte = (
  chatCtl: ChatController,
  message: MessageContent,
  index: number,
) => {
  if (localStorage.getItem('select') == message) {
    const forLangText = localStorage.getItem('text')
    /* istanbul ignore next */
    chatCtl.updateMessage(index, {
      content: forLangText ?? 'something went wrong',
      type: 'text',
      self: true,
    })
  }
}
export const sliderMessageUpdate = (
  chatCtl: ChatController,
  message: MessageContent,
  index: number,
) => {
  if (localStorage.getItem('slider-id') == message) {
    const forLangText = localStorage.getItem('slider-text')
    /* istanbul ignore next */
    chatCtl.updateMessage(index, {
      content: forLangText ?? 'something went wrong',
      type: 'text',
      self: true,
    })
  }
}

// for smoke test
/* istanbul ignore next */
export const checkLandscape = (isLandScapeMode: boolean) => {
  const url = window.location.href
  if (url.includes('12346971')) {
    return url.includes('landscape')
  } else if (isLandScapeMode) {
    return true
  } else {
    return false
  }
}
