import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import { SendUndoProps } from '../../../util/types'
import { UNDO_MSG_CONTENT } from '../../../util/constants'
import { container } from './styles'
import ActionButton from '../ActionButton'

const SendUndoButton: React.FC<SendUndoProps> = ({
  onClick,
  chatController,
  actionRequest,
  hideBackBtn,
  hideSendBtn,
  disableSendBtn,
}) => {
  const url = window.location.href
  const noUndo = url.indexOf('-no-undo')
  /* istanbul ignore next */
  const undoFunction = () => {
    const res = { type: 'undo', value: UNDO_MSG_CONTENT }
    chatController?.setActionResponse(actionRequest, res)
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Backspace' && !hideBackBtn) undoFunction()
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [chatController, hideBackBtn])

  return (
    <Box sx={container}>
      {!hideBackBtn && noUndo === -1 && (
        <Box>
          <ActionButton
            onClick={undoFunction}
            testId="landscape-back-button"
            text="Back"
          />
        </Box>
      )}
      {onClick && !hideSendBtn && (
        <Box>
          <ActionButton
            onClick={onClick}
            testId="landscape-send-button"
            text="Send"
            disabled={disableSendBtn}
          />
        </Box>
      )}
    </Box>
  )
}

export default SendUndoButton
