import { SxProps } from '@mui/system'
import { ThemeObjectInterFace } from '../../../util/types'

export const rankSorterResponse: SxProps = {
  my: 1,
  pr: '3%',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 2,
}

export const rankSorterWrapper = (
  globalTheme: ThemeObjectInterFace,
): SxProps => ({
  display: 'flex',
  position: 'relative',
  boxShadow: '3px  3.5px .5px 1px rgba(0, 0, 0, 0.3)',
  backgroundColor: globalTheme?.main_color,
  borderRadius: '10px 0px 10px 10px',
  color: 'primary.contrastText',

  '&::after': {
    content: '""',
    width: 0,
    height: 0,
    borderTop: '1px solid transparent',
    borderBottom: '15px solid transparent',
    borderLeft: `20px solid ${globalTheme?.main_color}`,
    top: 0,
    right: '-19px',
    position: 'absolute',
    filter: 'drop-shadow(2px 4px 0.5px rgba(0,0,0,.3))',
  },
})

export const arrowContainer: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'column',
}

export const arrowText: SxProps = { color: 'white', fontWeight: '500' }

export const arrowStyle = (scaleX: number): SxProps => ({
  width: 80,
  height: 30,
  transform: `scaleX(${scaleX})`,
  display: 'flex',
  rotate: '90deg',
  background:
    'linear-gradient(white 0 0) center/calc(100% - 30px) 10px, conic-gradient(from -136deg at right,#0000 , white 1deg 90deg,#0000 91deg) right/15px 100%,conic-gradient(from   44deg at left ,#0000 ,white 1deg 90deg,#0000 91deg) left /15px 100%',
  backgroundRepeat: 'no-repeat',
})
