import { SxProps } from '@mui/system'

export const styleForLandscapeMainBox: SxProps = {
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  '& > *': {
    flexBasis: 'calc(50% - 20px)',
  },
}

export const optionsContainer = (isLandScapeMode: boolean): SxProps => ({
  margin: isLandScapeMode ? null : '0 15px 10px',
  display: 'flex',
  justifyContent: isLandScapeMode ? 'center' : 'flex-end',
  flexWrap: 'wrap',
})
