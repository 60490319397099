import { SxProps } from '@mui/system'

export const landscapeImageStyle: SxProps = {
  maxHeight: '600px',
  maxWidth: '600px',
  borderRadius: '10px',
  width: '100%',
}

export const questionContentContainer = (isImage: boolean): SxProps => ({
  minHeight: { md: isImage ? '100%' : '80vh', xs: isImage ? '100%' : '70vh' },
  display: 'flex',
  justifyContent: isImage ? 'space-between' : 'center',
  alignItems: 'center',
  padding: '20px 14px',
  backgroundColor: 'white',
  border: `2px solid`,
  borderRadius: 5,
  flexDirection: 'column',
  gap: '10px',
  boxShadow:
    'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;',
})

export const textContent: SxProps = {
  color: '#000',
  lineHeight: 1.3,
  textAlign: 'center',
  fontWeight: 300,
  fontSize: {
    md: 20,
    sm: 16,
  },
  whiteSpace: 'pre-wrap',
  marginBottom: 0,
  '& *:not(:last-child)': {
    marginBottom: '5px',
  },
}
