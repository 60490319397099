import React, { forwardRef, useMemo } from 'react'
import {
  ChildValueProps,
  GridSelectAction,
  SelectedtValue,
} from '../../../../../util/types'
import { gridButtonText, gridValueContent } from '../../style'
import { useThemeContext } from '../../../../../globalContext/global-context'
import { Box } from '@mui/material'
import { Hints } from '../../../../ui'

type GridSelectButtonProps = {
  actionRequest: GridSelectAction
  handleSelect: (parentIndex: number, childValue: ChildValueProps) => void
  selectedValue: SelectedtValue[]
  index: number
  buttonContainerHeight: number[]
  childValue: ChildValueProps
}

const GridSelectButtons = forwardRef<HTMLDivElement, GridSelectButtonProps>(
  (
    {
      actionRequest,
      handleSelect,
      index,
      selectedValue,
      buttonContainerHeight,
      childValue,
    },
    questionRefs,
  ) => {
    const { globalObject } = useThemeContext()
    const globalTheme = globalObject?.themeData?.theme

    const isSelected = useMemo(() => {
      return selectedValue.some((item) => {
        return item.key === index && item.value === childValue.value
      })
    }, [selectedValue])

    const currentText = (childValue: ChildValueProps) => {
      /* istanbul ignore next */
      return actionRequest?.type == 'grid_select'
        ? childValue.text
        : childValue.value
    }

    /* istanbul ignore next */
    if (!questionRefs) return <Box />
    return (
      <Box
        ref={questionRefs}
        onClick={() => {
          !childValue?.disabled && handleSelect(index, childValue)
        }}
        data-testId={`chip-${index}-${childValue.value}`}
        sx={{
          ...gridValueContent(
            globalTheme,
            isSelected,
            childValue.disabled,
            buttonContainerHeight[index],
          ),
        }}
      >
        <Hints sx={gridButtonText(isSelected)} text={currentText(childValue)} />
      </Box>
    )
  },
)

export default GridSelectButtons
