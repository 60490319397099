import { SxProps } from '@mui/system'
import { ThemeObjectInterFace } from '../../../util/types'

const widthOfArrow = '15px'
const heightOfArrowPortrait = '100px'
const heightOfArrowLandscape = '25vh'

export const rankSorterContainer: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}

export const rankBoxContainer = (
  globalTheme: ThemeObjectInterFace,
  isLandScapeMode: boolean,
): SxProps => ({
  minHeight: isLandScapeMode ? '35vh' : null,
  padding: isLandScapeMode
    ? { md: '20px 16px', xs: '18px 14px' }
    : '8px 16px 16px',
  margin: '8px 16px',
  borderWidth: 2,
  boxShadow: 3,
  backgroundColor: 'white',
  borderRadius: 5,
  border: 2,
  borderColor: `${globalTheme?.main_color}`,
  flexBasis: isLandScapeMode ? { md: '35%', xs: '45%' } : null,
})

export const rankContent = (globalTheme: ThemeObjectInterFace): SxProps => ({
  color: `${globalTheme?.main_color}`,
  marginBottom: 1,
  textAlign: 'center',
  fontSize: {
    xs: 15,
  },
  fontWeight: '500',
})

export const rankBoxChild = (isCardType: boolean): SxProps => ({
  width: '100%',
  display: isCardType ? 'inherit' : 'flex',
})

export const landScapeQuestionBox = (
  isCardType: boolean,
  globalTheme: ThemeObjectInterFace,
): SxProps => ({
  // height: isLandScapeMode isGreaterLength ? '5.7rem' : '',
  minHeight: isCardType ? '30vh' : null,
  padding: isCardType ? '16px 8px' : null,
  border: isCardType ? `3px solid ${globalTheme?.main_color}` : null,
  backgroundColor: isCardType ? 'white' : null,
  color: globalTheme?.main_color,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  wordBreak: 'break-word',
})

export const potraitQuestionBox = (
  globalTheme: ThemeObjectInterFace,
): SxProps => ({
  marginBottom: 1,
  border: 3,
  marginTop: 4,
  marginRight: {
    sm: 5,
    xs: 2,
  },
  borderColor: globalTheme?.main_color,
  padding: 1,
  paddingY: 4,
  height: '10rem',
  width: '40%',
  overflow: 'scroll',
  alignItems: 'center',
  display: 'flex',
  color: globalTheme?.main_color,
  textAlign: 'center',
  fontWeight: 500,
  wordBreak: 'break-word',
  fontSize: 12,
})

export const rankBoxWrapper = (isCardType: boolean): SxProps => ({
  display: 'flex',
  width: '100%',
  justifyContent: isCardType ? 'space-around' : 'initial',
})

export const arrowBox = (
  isLandScapeMode: boolean,
  isCardType: boolean,
): SxProps => ({
  width: {
    sm: isLandScapeMode ? '100%' : '50%',
    xs: '70%',
  },
  marginLeft: {
    sm: isCardType ? 4 : 0,
    xs: isCardType ? 2 : 0,
  },
  marginTop: {
    sm: isCardType ? 2 : 0,
    xs: isCardType ? 1 : 0,
  },
})

export const arrowBoxWrapper = (isCardType: boolean): SxProps => ({
  display: 'flex',
  justifySelf: 'self-start',
  gap: isCardType ? 3 : 0,
})

export const arrowBoxWrapperFirstChild = (
  isCardType: boolean,
  values: string[],
): SxProps => ({
  display: 'flex',
  gap: '5px',
  alignItems: 'center',
  width: isCardType ? '15%' : '30%',
  justifyContent: values.length > 5 ? 'space-between' : 'center',
  flexDirection: 'column',
})

export const arrowLabel = (globalTheme: ThemeObjectInterFace): SxProps => ({
  color: globalTheme?.main_color,
  fontWeight: '500',
  fontSize: '18px',
})

export const arrowContainer: SxProps = {
  width: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}

export const arrowTop: SxProps = {
  width: 0,
  height: 0,
  borderLeft: `${widthOfArrow} solid transparent`,
  borderRight: `${widthOfArrow} solid transparent`,
}

export const arrowMiddle = (
  globalTheme: ThemeObjectInterFace,
  isLandScapeMode: boolean,
): SxProps => ({
  width: widthOfArrow,
  minHeight: isLandScapeMode ? heightOfArrowLandscape : heightOfArrowPortrait,
  backgroundColor: globalTheme.main_color,
})

export const valueContainer = (
  values: string[],
  isLandScapeMode: boolean,
): SxProps => ({
  width: '100%',
  minHeight: isLandScapeMode ? heightOfArrowLandscape : heightOfArrowPortrait,
  marginTop: values.length > 5 ? 4 : '43px',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'scroll',
})

export const valueStyle = (globalTheme: ThemeObjectInterFace): SxProps => ({
  color: globalTheme?.main_color,
  fontSize: {
    sm: 20,
    xs: 16,
  },
})

export const rightSideContainer = (): SxProps => ({
  flexBasis: '50%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '5px',
})
