import { SxProps } from '@mui/system'
import { ThemeObjectInterFace } from '../../../util/types'

export const inputContainer = (globalTheme: ThemeObjectInterFace): SxProps => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderRadius: '0px 0px 20px 20px',
  alignItems: 'center',
  backgroundColor: globalTheme?.main_color,
  padding: '15px 0 15px 15px',
})

export const undoButtonWrapper: SxProps = { marginRight: 1.7, paddingTop: 0.5 }

export const undoIconWrapper: SxProps = { marginLeft: 1.7, paddingTop: 0.5 }

export const buttonStyle: SxProps = {
  backgroundColor: '#ffffff8c',
  color: '#fff',
  textTransform: 'initial',
}

export const placeholderContainer: SxProps = {
  fontSize: 16,
  backgroundColor: 'white',
  flex: 1,
  borderRadius: '15px',
  paddingTop: '5px',
  paddingBottom: '5px',
  paddingLeft: '10px',
  color: '#7d7d7d',
}
