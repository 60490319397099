import { FC } from 'react'
import { ActionButtonProps } from '../../../util/types'
import { Button } from '@mui/material'
import { actionBtn } from './styles'
import { useThemeContext } from '../../../globalContext/global-context'

const ActionButton: FC<ActionButtonProps> = ({
  onClick,
  testId,
  text,
  disabled,
  isSelected,
}) => {
  const { globalObject } = useThemeContext()
  const globalTheme = globalObject.themeData.theme

  return (
    <Button
      data-testid={testId}
      disabled={disabled}
      onClick={onClick}
      sx={actionBtn(globalTheme, !!isSelected)}
    >
      {text}
    </Button>
  )
}

export default ActionButton
