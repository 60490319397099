import { SxProps } from '@mui/system'
import { CSSProperties } from 'react'

export const videoContainer = (
  isSelf: boolean,
  isLandScapeMode: boolean,
): SxProps => ({
  paddingLeft: !isLandScapeMode && isSelf ? '20%' : '20px',
  my: isLandScapeMode ? null : 1,
  gap: 2,
  display: isLandScapeMode ? 'flex' : null,
  justifyContent: 'space-between',
  alignItems: 'center',
  '& > *': {
    flexBasis: 'calc(50% - 20px)',
  },
})

export const videoEndcontainer: SxProps = {
  height: 275,
  width: '100%',
  backgroundColor: 'black',
  color: 'white',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  borderRadius: 3,
  fontSize: {
    sm: 25,
    xs: 20,
  },
  paddingRight: 3,
  paddingLeft: 3,
  textAlign: 'center',
}

export const videoIframe = (isLandScapeMode: boolean): CSSProperties => ({
  height: isLandScapeMode ? '80vh' : 275,
  width: '100%',
  borderRadius: 10,
  borderWidth: 0,
})
